import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { MainRouter } from './MainRouter';

function App() {
    return (
        <MainRouter/>
    );
}

export default App;
