import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import aboutusImage from '../images/about-us.png';

export const About = () => {
    return (
        <>
            <Row>
                <Col>
                    <h2>About us</h2>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <p className="wj-justify">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Nulla varius vel risus et sodales. Ut rutrum velit vel tincidunt gravida.
                Sed vel urna ac massa pharetra bibendum a ut erat.
                Mauris ac mauris auctor, mollis est non, condimentum purus.
                Aliquam vitae interdum libero.
                Vivamus porta, dui eu tempus ornare, est dolor posuere dui, id fringilla magna velit quis turpis.
                Donec et nunc dolor. Ut iaculis vel odio eget gravida.
                Nullam pulvinar sapien diam, at pulvinar sem gravida in.
                Quisque pellentesque interdum ex, sed suscipit felis sagittis in.
                Sed a mi in nisi luctus bibendum eget vel orci. Mauris pretium id est nec pulvinar.
                Morbi pretium elit egestas augue faucibus ullamcorper. Nunc blandit augue quam.
                Suspendisse a volutpat ante, ut laoreet ante.
                    </p>
                </Col>
                <Col sm="6">
                    <Image src={aboutusImage} style={{width: '100%'}}/>
                </Col>
            </Row>
        </>
    )
}
