import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { SearchResultTemplate } from './SearchResultTemplate';

export const FrontTemplate = ({results}) => {
    return (
        <>
            <Row>
                <Col>
                    <h2>Featured Jobs:</h2>
                </Col>
            </Row>
            {results.length === 0 ?
            'Loading...'
            :
            results.map((result, idx) => (
                <Row key={idx} className="py-3">
                    <Col>
                        <SearchResultTemplate
                            result={result} idx={idx}
                        />
                    </Col>
                </Row>
            ))}
        </>
    )
}
