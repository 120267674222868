import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Layout } from './templates/Layout';
import { Front } from './Front';
import { Search } from './Search';
import { Contact } from './Contact';
import { About } from './templates/About';

export const MainRouter = () => {
    return (
        <Router>
            <Switch>
                <Route path="/search">
                    <Layout>
                        <Search/>
                    </Layout>
                </Route>
                <Route path="/contact">
                    <Layout>
                        <Contact/>
                    </Layout>
                </Route>
                <Route path="/about">
                    <Layout>
                        <About/>
                    </Layout>
                </Route>
                <Route path="/">
                    <Layout>
                        <Front/>
                    </Layout>
                </Route>
            </Switch>
        </Router>
    )
}
