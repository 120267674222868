import React from 'react';

import Container from 'react-bootstrap/Container';

import { HeaderMenu } from './HeaderMenu';
import { FooterMenu } from './FooterMenu';

export const Layout = (props) => {
    return (
        <div className="d-flex flex-column wj-body">
            <HeaderMenu {...props}/>
            <Container className="flex-grow-1">
                {props.children}
            </Container>
            <FooterMenu {...props}/>
        </div>
    )
}
