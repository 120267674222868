import React from 'react';
import { ContactTemplate } from './templates/ContactTemplate';

export const Contact = () => {
    const onSubmitMessage = (e) => {
        e.preventDefault();
    }

    return (
        <ContactTemplate
            onSubmitMessage={onSubmitMessage}
        />
    )
}