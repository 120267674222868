import React from 'react';

import Webclient from './Webclient';
import { SearchTemplate } from './templates/SearchTemplate';

export const Search = () => {
    const [keywords, setKeywords] = React.useState('');
    const [region, setRegion] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState([]);

    const doSearch = (query) => {
        if (loading) return;

        setLoading(true);
        setResults([]);

        const wc = new Webclient();
        wc.doSearch({query: query, region: region})
            .then((jobs) => {
                setResults(jobs);
            }).catch((err) => {
                console.log('search err', err);
            }).finally(() => {
                setLoading(false);
            });
    }

    const onSubmitAll = (e) => {
        e.preventDefault();
        doSearch('');
    }

    const onSubmit = (e) => {
        e.preventDefault();
        doSearch(keywords);
    }

    return (
        <SearchTemplate
            loading={loading}
            results={results}
            keywords={keywords}
            setKeywords={setKeywords}
            region={region}
            setRegion={setRegion}
            onSubmit={onSubmit}
            onSubmitAll={onSubmitAll}
        />
    )
}
