import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const SearchResultTemplate = ({result, idx}) => {
    return (
        <>
            <Row>
                <Col><h5>{idx+1}. {result.title}</h5></Col>
            </Row>
            {!result.category ? null :
                <Row>
                    <Col>Category: {result.category}</Col>
                </Row>
            }
            <Row>
                <Col>Location: {result.location}</Col>
            </Row>
            <Row>
                <Col>Regions: {result.regionList.join(', ')}</Col>
            </Row>
            <Row>
                <Col className="wj-justify">{result.description}</Col>
            </Row>
        </>
    )
}
