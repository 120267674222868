import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export const ContactTemplate = ({onSubmitMessage}) => {
    return (
        <>
            <Row>
                <Col>
                    <h2>Contact us</h2>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <h4>Need help? We are here for you.</h4>
                    <Card className="my-2">
                        <Card.Body>
                            <Card.Title>Call us</Card.Title>
                            <Card.Text>
                                Phone: 888-555-1212<br/>
                                Working hours: 9.00 - 18.00<br/>
                                Monday - Friday
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="my-2">
                        <Card.Body>
                            <Card.Title>Write us</Card.Title>
                            <Card.Text>
                                Job for Job Ltd.<br/>
                                1212 Broadway, Ste 108A<br/>
                                New York, NY  10001<br/>
                                U.S.A.<br/>
                                <br/>
                                Job for Job GmbH<br/>
                                Unter den Linden 28<br/>
                                10117 Berlin, Germany<br/>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="6">
                    <h4>Send a message</h4>
                    <Form onSubmit={onSubmitMessage}>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Your name"/>
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>email</Form.Label>
                            <Form.Control type="email" placeholder="Your email"/>
                        </Form.Group>
                        <Form.Group controlId="formText">
                            <Form.Label></Form.Label>
                            <Form.Control as="textarea" rows={5}/>
                        </Form.Group>
                        <Button className="mt-3" type="submit">Send</Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
