import React from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import { Link, NavLink } from 'react-router-dom';

import { Info, Search } from 'react-feather';

export const HeaderMenu = () => {
    return (
        <Navbar className="wj-headermenu">
            <Container>
            <Navbar.Brand>
                <Link to="/">Home</Link>
            </Navbar.Brand>
            <Navbar.Collapse>
                <Nav className="mx-2">
                    <NavLink to="/search"><Search size={18}/> <span>Search</span></NavLink>
                </Nav>
                <Nav className="mx-2">
                    <NavLink to="/contact"><Info size={18}/> <span>Contact</span></NavLink>
                </Nav>
                <Nav className="mx-2">
                    <NavLink to="/about"><Info size={18}/> <span>About</span></NavLink>
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
