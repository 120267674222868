import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';

import { SearchResultTemplate } from './SearchResultTemplate';

export const SearchTemplate = ({results, loading, region, setRegion, keywords, setKeywords, onSubmit, onSubmitAll}) => {
    return (
        <Form onSubmit={onSubmit}>
            <Row>
                <Col>
                    <h2>Search</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    I'm located in:
                    <select className="ms-2" value={region} onChange={(e) => setRegion(e.target.value)}>
                        <option value=""></option>
                        <option value="bg">Bulgaria</option>
                        <option value="pl">Poland</option>
                    </select>
                </Col>
            </Row>
            <Row className="pt-3">
                <Col>
                    <InputGroup>
                        <FormControl placeholder="search"
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                            />
                        <InputGroup.Append>
                            <Button variant="outline-success" onClick={onSubmit}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    hidden={!loading}
                                />
                                Search
                            </Button>
                            <Button variant="outline-primary" onClick={onSubmitAll}>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    hidden={!loading}
                                />
                                Show All
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
            {results.map((result, idx) => (
                <Row key={idx} className="py-3">
                    <Col>
                        <SearchResultTemplate
                            result={result} idx={idx}
                        />
                    </Col>
                </Row>
            ))}
        </Form>
    )
}
