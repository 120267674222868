import React from 'react';

import Container from 'react-bootstrap/esm/Container';

export const FooterMenu = () => {
    return (
        <footer className="wj-footermenu">
            <Container>
                &copy; 2021
            </Container>
        </footer>
    )
}
