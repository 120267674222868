import React from 'react';

import { FrontTemplate } from './templates/FrontTemplate';
import Webclient from './Webclient';

export const Front = () => {
    const [results, setResults] = React.useState([]);
    const [region, setRegion] = React.useState('');

    const wc = new Webclient();

    React.useEffect(() => {
        wc.doFeatured({region}).then((jobs) => {
                setResults(jobs);
            }).catch((err) => {
                console.log('featured err', err);
            });
    }, []);

    return (
        <FrontTemplate
            results={results}
        />
    )
}
