import React from 'react';

import axios from 'axios';

const PREFIX = 'https://omega2.durdom.com/api/1/';

const METHOD = {
    POST: 'POST',
    GET: 'GET',
};

export default class Webclient extends React.PureComponent {
    doSearch({region, query}) {
        return new Promise((resolve, reject) => {
            this.makeRequest({
                url: PREFIX + 'jobs',
                method: METHOD.GET,
                data: {region, query},
            }).then((result) => {
                const jobs = result && result.jobs;
                resolve(jobs || []);
            }).catch(reject);
        });
    }

    doFeatured({region}) {
        return new Promise((resolve, reject) => {
            this.makeRequest({
                url: PREFIX + 'jobs/featured',
                method: METHOD.GET,
                data: {region},
            }).then((result) => {
                const jobs = result && result.jobs;
                resolve(jobs || []);
            }).catch(reject);
        });
    }

    makeRequest({url, method, data}) {
        return new Promise((resolve, reject) => {
            axios({
                method: method,
                url: url,
                data: method === METHOD.POST ? data : null,
                params: method === METHOD.GET ? data : null,
            }).then((data) => {
                if (data.status === 200) {
                    resolve(data.data);
                } else {
                    reject(data);
                }
            }).catch(reject);
        });
    }
}
